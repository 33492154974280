<template>
  <section :class="['container', {'ocultar-overflow-y': siMuestraRutaHija}]">
    <article class="pt-3">
      <div class="vista-encabezado">
        <header class="col titulo h4-responsive text-center text-md-left">
          Filtros de búsqueda
        </header>
        <div class="col-auto boton-primario">
          <mdb-btn
            color="primario"
            icon="plus"
            class="px-3 ml-5 ml-md-0"
            @click="mostrarFormularioCliente = !mostrarFormularioCliente"
          >
            Agregar empresa
          </mdb-btn>
        </div>
      </div>
      <div class="row">
        <FiltrosTipo1
          :zonas="zonasFiltro"
          :tipos-cli="tiposFiltro"
          cli-potenciales
          @zonasAFiltrar="aplicarFiltrosZonas($event.estados, $event.municipios, $event.parroquias)"
          @tiposAFiltrar="tiposAFiltrar=$event; aplicarFiltros()"
          @nombreAFiltrar="nombreAFiltrar=$event; aplicarFiltros()"
          @estatusFacturaAFiltrar="estatusFacturaAFiltrar=$event; aplicarFiltros()"
          @fechaAFiltrar="fechaAFiltrar=$event; aplicarFiltros()"
          @clientesPo="clientesPo=$event; aplicarFiltros()"
          @eliminarFiltros="eliminarFiltrosSeleccionados"
        />
      </div>
      <div
        v-if="cargando"
        class="mensaje-no-items py-3"
      >
        <LoaderKel />
        <p class="texto mt-2">
          Cargando
        </p>
      </div>
      <ul
        v-else-if="Object.keys(clientes).length"
        class="table t-clientes"
      >
        <li class="encabezado sticky">
          <div class="col-12 col-md px-0 pl-4 pl-md-0 text-center text-sm-left">
            <header class="h4-responsive">Clientes</header>
            <p class="descripcion text-capitalize">
              En lista: {{ Object.keys(clientes).length }} empresas,
              {{ totalSucursales }} sucursales
            </p>
          </div>
          <div class="thead claro">
            <div class="col">Empresa</div>
            <div class="col">Tipos</div>
            <div class="col">Estatus de factura</div>
            <div class="col">Zonas</div>
            <div class="col-12 col-sm col-lg-2">Acción</div>
          </div>
        </li>
        <router-link
          :to="{name: 'Cliente', params: {id: empresa.id_emp} }"
          custom
          v-slot="{navigate}"
          v-for="empresa in clientes"
          :key="empresa.id_emp"
          class="contenido cursor-pointer"
        >
          <li @click="navigate">
            <div class="col" data-columna="Empresa">
              <p class="text-capitalize">{{ empresa.nom_emp }}</p>
            </div>
            <div class="col" data-columna="Tipos">
              <div>
                <span v-for="tipo in empresa.tipos" :key="tipo" class="auto-coma">
                  <template v-if="tipos[tipo] ">
                    {{ tipos[tipo] && tipos[tipo].nom_tcl ? tipos[tipo].nom_tcl  : ''}}
                  </template>
                  <template v-else>
                    <span class="text-muted">Sin tipo especificado</span>
                  </template>
                </span>
              </div>
            </div>
            <div class="col" data-columna="Condición de factura">
              <p :class="[asignarColorEstado(empresa.est_fac), 'text-capitalize font-weight-bold']">
                {{empresa.est_fac}}
              </p>
            </div>
            <div class="col" data-columna="Zonas">
              <div>
                <span v-for="zona in empresa.zonas" :key="zona" class="auto-coma">
                  <template v-if="zonas[zona]">
                    {{ zonas[zona].est_zon ? zonas[zona].est_zon  : 'Sin estado'}}, {{ zonas[zona].nom_zon  }}
                    {{ zonas[zona].sec_zon ? zonas[zona].sec_zon : 'Sin sector' }}
                  </template>
                  <template v-else>
                    <span class="text-muted"> Sin zona especificada</span>
                  </template>
                </span>
              </div>
            </div>
            <div
              class="col-12 col-sm col-lg-2 px-lg-0"
              data-columna="Acción"
            >
              <div @click.stop.prevent="idClienteSeleccionado = empresa.id_emp; modalConfirmacionEliminar = !modalConfirmacionEliminar">
                <mdb-btn
                  flat
                  dark-waves
                  icon="trash"
                  class="px-3 px-sm-0"
                >
                  Eliminar
                </mdb-btn>
              </div>
            </div>
          </li>
        </router-link>
      </ul>
      <div
        v-else-if="!Object.keys(clientes).length && filtrosActivos"
        class="mensaje-no-items py-5"
      >
        <span class="fa-stack icono position-relative ml-n4 mb-4">
          <font-awesome-icon
            icon="search"
            size="5x"
            class="fa-stack-2x"
          />
          <font-awesome-icon
            icon="times"
            size="2x"
            class="fa-stack-1x"
            style="position: absolute;top: -10px;left: 14px;"
          />
        </span>
        <p class="texto text-center">
          No se encontraron empresas
          <span class="d-block">durante la búsqueda</span>
        </p>
      </div>
      <div
        v-else
        class="mensaje-no-items py-5"
      >
        <font-awesome-icon
          icon="search"
          size="5x"
          class="icono"
        />
        <p class="texto text-center">
          Consulta los clientes a través de
          <span class="d-block">los filtros para continuar</span>
        </p>
      </div>
    </article>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight contenedor-hijo"
      leave-active-class="animated fadeOutRight contenedor-hijo"
    >
      <router-view />
    </transition>
    <FormularioCliente
      :zonas="zonasFormulario"
      :tipos="tiposFormulario"
      :mostrarModal="mostrarFormularioCliente"
      @alertaMensaje="alertaMensaje = $event"
      @cerrar="mostrarFormularioCliente=false"
      @clienteDuplicado="modalConfirmacionClienteExistente = true; idClienteSeleccionado = $event"
      @clienteAgregado="cargaInicial(); idClienteAgregado = $event; mostrarAlertaSnackBar = true"
    />
    <ConfirmacionEliminar
      :boton-deshabilitado="botonDeshabilitado"
      :mostrar-modal="modalConfirmacionEliminar"
      titulo="¡Esta acción es irreversible!"
      mensaje="Se eliminará el cliente junto a todas sus sucursales en su totalidad."
      @cerrar="modalConfirmacionEliminar = false; idClienteSeleccionado=''"
      @confirmar="eliminarCliente()"
    />
    <ConfirmacionClienteExistente
      :boton-deshabilitado="botonDeshabilitado"
      :mostrar-modal="modalConfirmacionClienteExistente"
      titulo="El cliente ya se encuentra registrado por otro vendedor"
      mensaje="¿Deseas crearle una sucursal al cliente existente?"
      textoBotonConfirmar="Continuar"
      @cerrar="modalConfirmacionClienteExistente = false; idClienteSeleccionado=''"
      @confirmar="mostrarFormularioSucursal = true"
    />
    <FormularioSucursal
      :zonas="zonasFiltro"
      :tipos="tiposFiltro"
      :retenciones="retFiltro"
      :empresaId="idClienteSeleccionado"
      :esSucursal="true"
      :mostrarModal="mostrarFormularioSucursal"
      @alertaMensaje="alertaMensaje = $event"
      @cerrar="mostrarFormularioSucursal=false;"
      @clienteAgregado="cargaInicial();modalConfirmacionClienteExistente = false; mostrarFormularioCliente = false;"
    />
    <AlertaMensaje
      :alertaMensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <AlertaSnackBar
      :alerta-snack-bar="{
        color: 'informativo',
        contenido: 'Debes crear una sucursal al cliente para continuar',
      }"
      :btn-accion="{ icono: 'plus', texto: 'Agregar sucursal'}"
      :mostrar-snack-bar="mostrarAlertaSnackBar"
      @btn-accion="$router.push(redireccionarClienteSnackBar); mostrarAlertaSnackBar = false"
    />
  </section>
</template>

<script>
// @ is an alias to /src
import {asignarColorEstado, comprobarRutaHija} from '@/funciones/funciones.js';
import ConfirmacionEliminar from '@/components/ConfirmacionEliminar.vue';
import ConfirmacionClienteExistente from '@/components/ConfirmacionEliminar.vue';
import {mdbBtn} from 'mdbvue';
import AlertaMensaje from '@/components/AlertaMensaje.vue';
import AlertaSnackBar from '@/components/AlertaSnackBar.vue';
import {apiPost} from '@/funciones/api.js';
import FormularioCliente from '@/components/modals/FormularioCliente.vue';
import FormularioSucursal from '@/components/modals/FormularioCliente.vue';
import FiltrosTipo1 from '@/components/FiltrosTipo1.vue';
import LoaderKel from '@/components/LoaderKel.vue';

export default {
  name: 'Clientes',
  components: {
    mdbBtn,
    FormularioCliente,
    AlertaMensaje,
    AlertaSnackBar,
    FiltrosTipo1,
    ConfirmacionEliminar,
    ConfirmacionClienteExistente,
    FormularioSucursal,
    LoaderKel,
  },
  data() {
    return {
      asignarColorEstado,
      // Datos para formulario cliente
      zonasFormulario: [],
      tiposFormulario: [],
      vendedoresFormulario: [],
      // Cliente existente
      modalConfirmacionClienteExistente: false,
      idClienteAgregado: '',
      mostrarFormularioSucursal: false,
      // otros
      totalSucursales: 0,
      zonas: {},
      zonasFiltro: [],
      tipos: {},
      tiposFiltro: [],
      clientes: {},
      tiposAFiltrar: [],
      nombreAFiltrar: '',
      fechaAFiltrar: '',
      estadosAFiltrar: [],
      municipiosAFiltrar: [],
      parroquiasAFiltrar: [],
      estadoSeleccionado: '',
      retFiltro: [],
      estatusFacturaAFiltrar: [],
      municipioSeleccionado: '',
      alertaMensaje: {
        contenido: '',
      },
      mostrarAlertaSnackBar: false,
      mostrarFormularioCliente: false,
      // Eliminar cliente
      botonDeshabilitado: false,
      modalConfirmacionEliminar: false,
      idClienteSeleccionado: '',
      cargando: false,
      clientesPo: false,
    };
  },
  computed: {
    filtrosActivos() {
      return (this.estadosAFiltrar.length || this.municipiosAFiltrar.length || this.parroquiasAFiltrar.length ||
        this.tiposAFiltrar.length || this.nombreAFiltrar.length || this.fechaAFiltrar.length);
    },
    redireccionarClienteSnackBar() {
      return `/clientes/${this.idClienteAgregado}`;
    },
    siMuestraRutaHija() {
      return comprobarRutaHija(this.$route.path.slice(1).split('/'));
    },
  },
  methods: {
    eliminarCliente() {
      apiPost({s: 'empresaEliminarMv'}, JSON.stringify({emp: Number(this.idClienteSeleccionado)}))
          .then((res) => {
            if (res.data.r) {
              this.alertaMensaje = {
                contenido: 'Operación exitosa',
                tipo: 'correcto',
              };
              this.modalConfirmacionEliminar = false;
              this.cargaInicial();
            } else {
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un problema',
                tipo: 'error',
              };
            }
          });
    },
    cargaInicial() {
      apiPost({s: 'clientes_generalMv'}, '').then((res) => {
        this.enlistarZonas(res.data.zon);
        this.zonasFiltro = res.data.zon;
        this.enlistarTipos(res.data.tcl);
        this.tiposFiltro = res.data.tcl;
        // Datos de selección de empresas
        this.zonasFormulario = res.data.zon;
        this.tiposFormulario = res.data.tcl;
        this.vendedoresFormulario = res.data.ven;
      });
      // Obtener retenciones
      apiPost({s: 'retencionesMv'}, '').then((res) => {
        // Datos de selección de empresas
        this.retFiltro = res.data.rets;
      });
      this.aplicarFiltros();
    },
    aplicarFiltros() {
      this.cargando = true;
      apiPost({s: 'clientes_filtradosMv'}, 'tipos=' + this.tiposAFiltrar + '&nombre=' + this.nombreAFiltrar + '&fecha_desde=' + this.fechaAFiltrar +
      '&estados=' + this.estadosAFiltrar + '&municipios=' + this.municipiosAFiltrar + '&parroquias=' + this.parroquiasAFiltrar + '&factura=' +
      this.estatusFacturaAFiltrar + '&clip=' + this.clientesPo)
          .then((res) => {
            this.enlistarEmpresas(res.data.emp);
            this.totalSucursales = res.data.emp.length;
          });
    },
    aplicarFiltrosZonas(estadosFil, municipiosFil, parroquiasFil) {
      this.estadosAFiltrar = [];
      this.municipiosAFiltrar = [];
      this.parroquiasAFiltrar = [];

      estadosFil.forEach((e) => {
        this.estadosAFiltrar.push(e.est_zon);
      });
      municipiosFil.forEach((m) => {
        this.municipiosAFiltrar.push(m.ciu_zon);
      });
      parroquiasFil.forEach((p) => {
        this.parroquiasAFiltrar.push(p.sec_zon);
      });

      this.aplicarFiltros();
    },
    eliminarFiltrosSeleccionados() {
      this.tiposAFiltrar = [];
      this.nombreAFiltrar = '';
      this.fechaAFiltrar = '';
      this.estadosAFiltrar = [];
      this.municipiosAFiltrar = [];
      this.parroquiasAFiltrar = [];
      this.clientes = {};
      this.totalSucursales = 0;
      this.aplicarFiltros();
    },
    enlistarEmpresas(data) {
      const empresas = {};
      data.forEach((e) => {
        if (!empresas[`${e.nom_emp}-${e.id_emp}`]) {
          empresas[`${e.nom_emp}-${e.id_emp}`] = {
            id_emp: e.id_emp,
            nom_emp: e.nom_emp,
            rif_emp: e.rif_emp,
            est_fac: e.est_fac,
            tipos: {},
            zonas: {},
          };
        }
        empresas[`${e.nom_emp}-${e.id_emp}`].tipos[e.id_tcl_suc] = e.id_tcl_suc;
        empresas[`${e.nom_emp}-${e.id_emp}`].zonas[e.id_zon_suc] = e.id_zon_suc;
      });
      this.clientes = empresas;
      this.cargando = false;
    },
    enlistarZonas(data) {
      const zonas = {};
      data.forEach((e) => {
        if (!zonas[e.id_zon]) {
          zonas[e.id_zon] = {
            id_zon: e.id_zon,
            est_zon: e.est_zon,
            ciu_zon: e.ciu_zon,
            sec_zon: e.sec_zon,
          };
        }
      });
      this.zonas = zonas;
    },
    enlistarTipos(data) {
      const tipos = {};
      data.forEach((e) => {
        if (!tipos[e.id_tcl]) {
          tipos[e.id_tcl] = {
            id_tcl: e.id_tcl,
            nom_tcl: e.nom_tcl,
          };
        }
      });
      this.tipos = tipos;
    },
  },
  mounted() {
    this.cargaInicial();
  },
};
</script>
<style lang="scss" scoped>
.container {
  .table.t-clientes {
    margin: 1.5rem auto 4.5rem;

    .contenido > [class*='col'] {
      line-height: 1.3;
    }

    // Define los breakpoints para el responsive de ul.table
    $breakpointToChange: '576px';
    @include ul-table-responsive-at($breakpointToChange);

    // Anchos específicos de columnas
    @media screen and (min-width: $breakpointToChange) {
      .acciones {
        width: 140px;
      }
    }
  }
}
</style>
