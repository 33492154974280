/**
 * Formatos de impresión.
 * Ordenados alfabéticamente.
 * @returns {Object} Nombre del documento y Formato | Vista de impresión
 */

/**
 * TABLA DESPACHO TRANSPORTISTA
 * Componente: VerificacionCliente.vue
 * @param {String} transportista Nombre y apellido del transportista
 * @param {String} zonas Lista de zonas, separdas por comas
 * @param {Array} clientes Array de clientes, en estructura del table body
 * @return {Object} Nombre del documento y Formato | Vista de impresión
 */
function VerificacionClienteTransportista(transportista, zonas, clientes) {
  const nombre = `Despacho transportista ${transportista} - Zonas ${zonas}`;
  const formato = [
    {text: `Transportista: ${transportista}`},
    {
      text: `Zonas: ${zonas}`,
      color: 'gray',
    },
    {
      text: 'Verificación cliente',
      style: 'header2',
      alignment: 'center',
      margin: [0, 5, 0, 10],
    },
    {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        widths: [12, '*', 'auto', 'auto', '*', '*'],
        body: clientes,
      },
    },
  ];

  return {nombre, formato};
}

/**
 * TABLA PEDIDO VS DESPACHO
 * Componente: PedidovsDespacho.vue
 * @param {String} fecha Fecha seleccionada del pedido
 * @param {Array} pedidos Array de los pedidos, en estructura del table body
 * @return {Object} Nombre del documento y Formato | Vista de impresión
 */
function pedidosvsDespacho(fecha, pedidos) {
  // AGREGAR CONSULTA PARA SABER EL VENDEDOR ${vendedor.nombre} ${vendedor.apellido}
  const nombre = `Reporte Pedidos vs Despacho - Vendedor - Mes ${fecha}`;
  const formato = [
    {
      margin: [0, 0, 0, 10],
      columns: [
        [
          {
            text: `Mes: ${fecha}`,
          },
          // {
          //   text: `Vendedor: ${vendedor.nombre} ${vendedor.apellido}`,
          // },
        ],
        [
          {
            text: 'Reporte',
            style: 'header6',
            alignment: 'right',
          },
          {
            text: 'Pedidos vs Despacho',
            style: 'header2',
            alignment: 'right',
          },
        ],
      ],
    },
    {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        widths: ['40%', '20%', '20%', '20%'],
        body: pedidos,
      },
    },
  ];

  return {nombre, formato};
}

export {
  VerificacionClienteTransportista,
  pedidosvsDespacho,
};
