<template>
  <section :class="['container', {'ocultar-overflow-y': mostrandoComponenteHijo}]">
    <article class="pt-3">
      <div class="vista-encabezado">
        <header class="col titulo h4-responsive text-center text-md-left">
          Filtros de búsqueda
        </header>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-4 col-xl-3">
          <mdb-input
            class="my-2"
            v-model="fechaActividad"
            type="month"
            label="Mes"
            outline
          />
        </div>
        <div class="col-12 col-sm col-xl-3">
          <mdb-input
            class="my-2"
            v-model="fechaDel"
            type="date"
            label="Desde"
            outline
          />
        </div>
        <div class="col-12 col-sm col-xl-3">
          <mdb-input
            class="my-2"
            v-model="fechaHasta"
            type="date"
            label="Hasta"
            outline
          />
        </div>
        <div
          v-if="fechaDel.length && fechaHasta.length"
          class="col d-flex justify-content-center"
        >
          <mdb-btn
            flat
            dark-waves
            icon="broom"
            class="my-0 px-1"
            @click="borrarFiltroRangoFecha()"
          >
            Restablecer filtros
          </mdb-btn>
        </div>
      </div>
      <!-- Listado de actividades por enviar -->
      <ul class="table actividades" v-if="Object.keys(actividadesAunSinEntregar).length" >
        <li class="encabezado sticky">
          <header class="h4-responsive">Actividades pendientes de enviar</header>
          <p class="descripcion">
            Total de actividades: {{actividadesAunSinEntregar && Object.keys(actividadesAunSinEntregar).length}}
          </p>
          <div class="thead claro">
            <div class="col-12 col-sm-2 col-md-3">
              Día Mes
              <span class="small-block">
                Hora
              </span>
            </div>
            <div class="col">
              Cliente - Sucursal
              <span class="small-block">
                Zona
              </span>
            </div>
            <div class="col-12 col-sm-4">
              Acciones
            </div>
          </div>
        </li>
        <li
          class="contenido cursor-pointer"
          v-for="actividad in actividadesAunSinEntregarOrdenadas"
          :key="actividad + '-' + actividad.id_emp + '-' + actividad.id_suc"
          @click="empresaDeLaActividad = actividad; mostrarAsideActividad = true"
        >
          <div
            class="col-12 col-sm-2 col-md-3 pr-2"
            data-columna="Día Mes. Hora"
          >
            <p>
              <span class="small-block">
                {{formatearFechaYHora(actividad.fec_cre_act)}}
              </span>
            </p>
          </div>
          <div
            class="col"
            data-columna="Cliente - Sucursal / Zona"
          >
            <p>
              <span class="text-capitalize">{{actividad.nom_emp}}</span> -
              <span class="text-uppercase">{{actividad.nom_suc}}</span>
              <span class="small-block">{{actividad.sec_zon}}</span>
            </p>
          </div>
          <div
            class="col-12 col-sm-4"
            data-columna="Acciones"
          >
            <p>
              <span v-for="(accion, i) in actividad.acciones" :key="accion+i">
                <span
                  v-if="accion"
                  :class="['text-capitalize', asignarIcono(accion)]"
                >
                  {{ accion ? accion : 'Sin acción'}}{{i == (actividad.acciones.length - 1) ? '. ' : ', '}}
                </span>
                <span v-else class="text-muted font-italic user-select-none">
                  Sin acciones especificadas
                </span>
              </span>
            </p>
          </div>
        </li>
      </ul>
      <!-- Listado de actividades -->
      <ul class="table actividades">
        <li class="encabezado sticky">
          <header class="h4-responsive">Actividades</header>
          <p class="descripcion">
            Total de actividades: {{actividades && Object.keys(actividades).length}}
          </p>
          <div class="thead claro">
            <div class="col-12 col-sm-2 col-md-3">
              Día Mes
              <span class="small-block">
                Hora
              </span>
            </div>
            <div class="col">
              Cliente - Sucursal
              <span class="small-block">
                Zona
              </span>
            </div>
            <div class="col-12 col-sm-4">
              Acciones
            </div>
          </div>
        </li>
        <li
          v-if="cargando"
          class="mensaje-no-items py-3"
        >
          <LoaderKel />
          <p class="texto mt-2">
            Cargando
          </p>
        </li>
        <!-- Mensaje para fecha sin actividades -->
        <li
          v-else-if="actividades && !Object.keys(actividades).length"
          class="mensaje-no-items py-4"
        >
          <font-awesome-icon
            icon="calendar-times"
            size="5x"
            class="icono"
          />
          <p class="texto text-center">
            No hay actividades en la fecha seleccionada
          </p>
        </li>
        <li
          class="contenido cursor-pointer"
          v-for="actividad in actividades"
          :key="actividad.id_act"
          @click="fechaDeActividadSeleccionada = actividad.fec_cre_act; $router.push(`/actividades/${actividad.id_int}`)"
        >
          <div
            class="col-12 col-sm-2 col-md-3 pr-2"
            data-columna="Día Mes. Hora"
          >
            <p>
              <span class="small-block">
                {{formatearFechaYHora(actividad.fec_cre_act)}}
              </span>
            </p>
          </div>
          <div
            class="col"
            data-columna="Cliente - Sucursal / Zona"
          >
            <p>
              <span class="text-capitalize">{{actividad.nom_emp}}</span> -
              <span class="text-uppercase">{{actividad.nom_suc}}</span>
              <span class="small-block">{{actividad.sec_zon}}</span>
            </p>
          </div>
          <div
            class="col-12 col-sm-4"
            data-columna="Acciones"
          >
            <p v-if="acciones[actividad.id_int]">
              <span
                v-for="(accion, i) in acciones[actividad.id_int].acciones"
                :key="i"
                :class="['text-capitalize', asignarIcono(accion)]"
              >
                {{ accion ? accion : 'Sin acción'}}{{i == (acciones[actividad.id_int].acciones.length - 1) ? '. ' : ', '}}
              </span>
            </p>
            <p v-else class="text-muted font-italic user-select-none">
              Sin tipo especificado
            </p>
          </div>
        </li>
      </ul>
    </article>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view :key="$route.fullPath" :fecha="fechaDeActividadSeleccionada"></router-view>
    </transition>
    <NuevaActividad
      :mostrarAsideActividad="mostrarAsideActividad"
      :idSucursal="empresaDeLaActividad && empresaDeLaActividad.id_suc ? empresaDeLaActividad.id_suc : ''"
      :empresa="empresaDeLaActividad"
      :soloEnviarActividad="true"
      :adicionalesDatosPago="{
        // Datos requeridos de la empresa
        id_emp: empresaDeLaActividad && empresaDeLaActividad.id_emp,
        nom_emp: empresaDeLaActividad && empresaDeLaActividad.nom_emp,
        rif_emp: empresaDeLaActividad && empresaDeLaActividad.rif_emp,
        // Datos requeridos de la sucursal
        id_emp_suc: empresaDeLaActividad && empresaDeLaActividad.id_emp,
        id_suc: empresaDeLaActividad && empresaDeLaActividad.id_suc,
        id_tcl_suc: empresaDeLaActividad && empresaDeLaActividad.id_tcl_suc,
        id_zon_suc: empresaDeLaActividad && empresaDeLaActividad.id_zon_suc,
        nom_suc: empresaDeLaActividad && empresaDeLaActividad.nom_suc,
      }"
      @cerrar="mostrarAsideActividad=false"
      @limpiarActividadGuardada="fechaHasta ? actualizarDeHasta() :  actualizarActividad();"
    />
  </section>
</template>

<script>
import {
  mdbInput,
  mdbBtn,
} from 'mdbvue';
import {apiPost} from '@/funciones/api.js';
import NuevaActividad from '@/components/NuevaActividad.vue';
import LoaderKel from '@/components/LoaderKel.vue';
import {iconosActividades} from '@/constantes/actividades';

export default {
  name: 'Actividades',
  components: {
    mdbInput,
    mdbBtn,
    NuevaActividad,
    LoaderKel,
  },
  data() {
    return {
      fechaActividad: '',
      fechaDeActividadSeleccionada: '',
      actividades: {},
      actividadesAunSinEntregar: {},
      acciones: {},
      fechaDel: '',
      fechaHasta: '',
      mes: {},
      // Enviar actividades guardadas
      mostrarAsideActividad: false,
      empresaDeLaActividad: {},
      cargando: false,
      iconosActividades,
    };
  },
  mounted() {
    this.fechaActividad = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2);
  },
  computed: {
    actividadesAunSinEntregarOrdenadas() {
      return Object.values(this.actividadesAunSinEntregar).length ?
        Object.values(this.actividadesAunSinEntregar).reverse() :
        [];
    },
    mostrandoComponenteHijo() {
      return this.$route.params.id && this.$route.params.id.length ? true : false;
    },
  },
  watch: {
    fechaActividad() {
      this.fechaDel = '';
      this.fechaHasta = '';
      this.actualizarActividad();
    },
    fechaHasta(valorFecha) {
      if (!valorFecha) return;
      this.actualizarDeHasta();
    },
  },
  methods: {
    asignarIcono(accion) {
      let clase = '';
      this.iconosActividades.forEach((icono) => {
        if (accion === icono.nombre) {
          clase = icono.clase;
        }
      });
      return clase;
    },
    enlistarAcciones(data) {
      const acciones = {};
      if (data) {
        data.forEach((e) => {
          if (!acciones[e.id_int]) {
            acciones[e.id_int] = {
              acciones: [],
            };
            acciones[e.id_int].acciones.push(e.nom_acc);
          } else {
            acciones[e.id_int].acciones.push(e.nom_acc);
          }
        });
        this.acciones = acciones;
        this.cargando = false;
      }
    },
    formatearFechaYHora(fecha) {
      const mesSinFormato = new Date(fecha.substring(5, 7));
      const opt = {month: 'short'};
      const mesLetras = mesSinFormato.toLocaleDateString('es-VE', opt);
      const dia = fecha.substring(8, 10);
      const hora = new Date(fecha).toLocaleString().substr(-14);
      return dia + ' ' + mesLetras + ' ' + hora;
    },
    actualizarActividad() {
      this.cargando = true;
      let data = this.$route.params.sucursal ? 's=' + this.$route.params.sucursal + '&' : '';
      data += this.fechaActividad ? 'y=' + this.fechaActividad.split('-')[0] + '&m=' + this.fechaActividad.split('-')[1] : '';
      apiPost({s: 'actCliM'}, data)
          .then((r) => {
            this.actividades = r.data.act;
            this.enlistarAcciones(r.data.acc);
            this.obtenerActividadesAunSinEntregar();
          });
    },
    actualizarDeHasta() {
      let data = this.$route.params.sucursal ? 's=' + this.$route.params.sucursal + '&' : '';
      data += this.fechaHasta ? 'del=' + this.fechaDel + '&al=' + this.fechaHasta : '';
      apiPost({s: 'actCliM'}, data)
          .then((r) => {
            this.actividades = r.data.act;
            this.enlistarAcciones(r.data.acc);
            this.obtenerActividadesAunSinEntregar();
          });
    },
    obtenerActividadesAunSinEntregar() {
      let actividadesGuardadas = [];
      if (window.localStorage.getItem(`actividades`)) {
        const actividadesGuardadasSinFormato = window.localStorage.getItem(`actividades`);
        actividadesGuardadas = JSON.parse(actividadesGuardadasSinFormato);
      }
      this.actividadesAunSinEntregar = actividadesGuardadas;
    },
    borrarFiltroRangoFecha() {
      this.fechaDel = '';
      this.fechaHasta = '';
      this.fechaActividad = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2);
      this.actualizarActividad();
    },
  },
};

</script>

<style lang="scss" scoped>
.table.actividades {
  margin: 2rem auto 3rem;
  // Define los breakpoints para el responsive de ul.table
  @include ul-table-responsive-at ("576px");
}
</style>
