<template>
  <section :class="['container', {'ocultar-overflow-y': siMuestraRutaHija}]">
    <article class="pt-3">
      <div class="vista-encabezado">
        <header class="col titulo h4-responsive text-center text-md-left">
          Filtros de búsqueda
        </header>
      </div>
      <div class="row">
        <div class="col-12 col-xl-6">
          <mdb-input
            v-model.trim="sucAFiltrar"
            type="text"
            label="Nombre o RIF de la sucursal"
            class="my-2"
            outline
            @input="filtroNombre"
          />
        </div>
        <div class="col-12 col-sm-6">
          <div class="md-form md-outline outline-select my-1">
            <select
              v-model="estatusDevolucion"
              id="estatus-select"
              class="custom-select"
              @change="filtroEstatus"
            >
              <option class="d-none" value="" disabled>
                Seleccione
              </option>
              <option
                value="En Espera"
              >
                En espera
              </option>
              <option
                value="Procesada"
              >
                Procesada
              </option>
            </select>
            <label
              for="estatus-select"
              :class="estatusDevolucion.length ? 'label-active' : 'label-inactive'"
            >
              Estatus
            </label>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-xl">
          <mdb-input
            v-model="fechaDevolucion"
            type="month"
            label="Mes"
            class="my-2"
            outline
          />
        </div>
        <div class="col-12 col-sm-6 col-xl">
          <mdb-input
            v-model="fechaDel"
            type="date"
            label="Desde"
            class="my-2"
            outline
          />
        </div>
        <div class="col-12 col-sm-6 col-xl">
          <mdb-input
            v-model="fechaHasta"
            type="date"
            label="Hasta"
            class="my-2"
            outline
          />
        </div>
        <mdb-btn
          v-if="(sucAFiltrar && sucAFiltrar.length)  || (estatusDevolucion && estatusDevolucion.length)  || (fechaDel.length && fechaHasta.length)"
          class="col-12 col-md-auto mx-0"
          flat
          dark-waves
          icon="broom"
          @click="restablecerFiltros()"
        >
          Restablecer filtros
        </mdb-btn>
      </div>
      <ul class="table devoluciones">
        <li class="encabezado sticky">
          <header class="h4-responsive">Reporte de devoluciones</header>
          <p class="descripcion">
            Devoluciones listadas: {{devoluciones && Object.keys(devoluciones).length}}
          </p>
          <div class="thead claro">
            <div class="col-12 col-sm-auto">
              Día
              <span class="small-block">
                Mes
              </span>
            </div>
            <div class="col">
              Cliente - Sucursal
              <span class="small-block">
              Zona
              </span>
            </div>
            <div class="col-12 col-sm col-md-2">
              Estado
            </div>
            <div class="col-12 col-sm col-md-4">
              Vendedor
              <span class="small-block">
                Observación
              </span>
            </div>
          </div>
        </li>
        <li
          v-if="cargando"
          class="mensaje-no-items py-3"
        >
          <LoaderKel />
          <p class="texto mt-2">
            Cargando
          </p>
        </li>
        <li
          v-else-if="devoluciones && !Object.keys(devoluciones).length"
          class="mensaje-no-items py-3"
        >
          <font-awesome-icon
            icon="file-invoice"
            size="5x"
            class="icono"
          />
          <p class="texto">
            No hay devoluciones con el filtro seleccionado
          </p>
        </li>
        <template v-else>
          <router-link
            :to="{name: 'Devolucion', params: {id: devolucion.id_dev} }"
            custom
            v-slot="{navigate}"
            v-for="devolucion in devoluciones"
            :key="devolucion.id_dev"
            class="contenido cursor-pointer"
          >
            <li @click="navigate">
              <div
                class="col-12 col-sm-auto columna-fecha"
                data-columna="Día / Mes"
              >
                <p>
                  {{devolucion.fec_dev.split('-')[2]}}
                  <span class="small-block">
                    {{mesEnLetra(devolucion.fec_dev)}}
                  </span>
                </p>
              </div>
              <div
                class="col"
                data-columna="Cliente - Sucursal / Zona"
              >
                <p>
                  {{devolucion.nom_emp}}- {{devolucion.nom_suc}}
                  <span class="small-block">
                    {{devolucion.id_dev}}, {{devolucion.ciu_zon}}
                  </span>
                </p>
              </div>
              <div
                class="col-12 col-sm col-md-2"
                data-columna="Estado"
              >
                <p
                  :class="!devolucion.est_dev
                    ? 'text-muted font-italic user-select-none'
                    : 'font-weight-bold ' + asignarColorEstado(devolucion.est_dev)"
                >
                  {{devolucion.est_dev ? devolucion.est_dev : 'Sin especificar'}}
                </p>
              </div>
              <div
                class="col-12 col-sm col-md-4"
                data-columna="Vendedor / Observación"
              >
                <p>
                  {{devolucion.nom_per}} {{devolucion.ape_per}}
                  <small :class="['d-block text-muted', {'font-italic user-select-none': !devolucion.obs_dev}]">
                    {{devolucion.obs_dev ? devolucion.obs_dev : 'Sin observación'}}
                  </small>
                </p>
              </div>
            </li>
          </router-link>
        </template>
      </ul>
    </article>
    <AlertaMensaje
      :alertaMensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view />
    </transition>
  </section>
</template>

<script>
import {mdbInput, mdbBtn} from 'mdbvue';
import {apiPost} from '@/funciones/api.js';
import {asignarColorEstado, comprobarRutaHija} from '@/funciones/funciones.js';
import AlertaMensaje from '@/components/AlertaMensaje.vue';
import LoaderKel from '@/components/LoaderKel.vue';
export default {
  name: 'Devoluciones',
  components: {
    mdbInput,
    mdbBtn,
    AlertaMensaje,
    LoaderKel,
  },
  data() {
    return {
      asignarColorEstado,
      fechaDevolucion: '',
      devoluciones: [],
      fechaDel: '',
      fechaHasta: '',
      sucAFiltrar: '',
      mes: {},
      estatusDevolucion: '',
      alertaMensaje: {
        contenido: '',
      },
      cargando: false,
    };
  },
  mounted() {
    this.fechaDevolucion = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2);
  },
  watch: {
    fechaDevolucion() {
      this.fechaDel = '';
      this.fechaHasta = '';
      this.actualizarDevoluciones();
    },
    fechaHasta() {
      if (!this.fechaHasta || !this.fechaDel) return;
      this.actualizarDevoluciones();
    },
  },
  computed: {
    siMuestraRutaHija() {
      return comprobarRutaHija(this.$route.path.slice(1).split('/'));
    },
  },
  methods: {
    actualizarDevoluciones() {
      this.cargando = true;
      let filtrosDevolucion = '';
      filtrosDevolucion += this.sucAFiltrar && this.sucAFiltrar.length ? 'nom=' + this.sucAFiltrar + '&' : '';
      filtrosDevolucion += this.estatusDevolucion && this.estatusDevolucion.length ? 'est=' + this.estatusDevolucion + '&' : '';
      if (!this.fechaHasta) {
        filtrosDevolucion += this.fechaDevolucion ? 'y=' + this.fechaDevolucion.split('-')[0] + '&m=' + this.fechaDevolucion.split('-')[1] : '';
      }
      filtrosDevolucion += this.fechaHasta ? 'del=' + this.fechaDel + '&al=' + this.fechaHasta : '';
      apiPost({s: 'devolucionesMv'}, filtrosDevolucion)
          .then((r) => {
            this.devoluciones = r.data.dev;
            this.cargando = false;
          }).catch(()=>{
            this.cargando = false;
            this.alertaMensaje = {
              contenido: 'Ocurrió un error al cargar las devoluciones',
              tipo: 'error',
            };
          });
    },
    mesEnLetra(fechaPlana) {
      const mess = new Date(fechaPlana.substring(5, 7));
      const opt = {month: 'short'};
      const mesLetras = mess.toLocaleDateString('es-VE', opt);
      return mesLetras;
    },
    filtroNombre(search) {
      if (!search) return;
      setTimeout(() => {
        this.actualizarDevoluciones();
      }, 500);
    },
    filtroEstatus() {
      if (!this.estatusDevolucion) return;
      this.actualizarDevoluciones();
    },
    restablecerFiltros() {
      this.fechaDel = '';
      this.fechaHasta = '';
      this.sucAFiltrar = '';
      this.estatusDevolucion = '';
      this.actualizarDevoluciones();
    },
  },
};

</script>

<style lang="scss" scoped>
.table.devoluciones {
  margin: 0 auto 3rem;
  @include ul-table-responsive-at ("576px");
}
</style>
