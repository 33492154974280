<template>
  <section>
    <article class="contenedor-hijo">
      <div class="container">
        <div
          :class="['row mx-0 cliente',
                  {'ocultar-overflow-y': mostrandoComponenteHijo}]"
        >
          <section :class="['col-12', {'col-md': mostrarAsideActividad}, 'pt-3']">
            <div class="row justify-content-center justify-content-md-between mr-0 ml-5 ml-lg-0 mb-3">
              <div class="col-auto px-0">
                <mdb-btn
                  dark-waves
                  flat
                  icon="arrow-left"
                  :class="['m-0', {'px-md-2': mostrarAsideActividad}]"
                  title="Regresar a Clientes"
                  @click="$router.go(-1)"
                >
                  Regresar
                </mdb-btn>
              </div>
              <div class="col-auto px-0">
                <mdb-btn
                  flat
                  v-if="empresa && Object.values(empresa).length"
                  dark-waves
                  icon="pen"
                  :class="['m-0', {'px-md-2': mostrarAsideActividad}]"
                  @click="esCliente = true; esEditar = true; mostrarFormularioCliente = !mostrarFormularioCliente"
                >
                  Editar cliente
                </mdb-btn>
              </div>
              <div class="col-auto px-0">
                <mdb-btn
                  flat
                  v-if="empresa && Object.values(empresa).length"
                  dark-waves
                  icon="plus"
                  title="Agregar sucursal"
                  :class="['m-0', {'px-md-2': mostrarAsideActividad}]"
                  @click="esSucursal = true; mostrarFormularioCliente = !mostrarFormularioCliente"
                >
                  Agregar sucursal
                </mdb-btn>
              </div>
            </div>
            <div
              v-if="cargando"
              class="mensaje-no-items py-3"
            >
              <LoaderKel />
              <p class="texto mt-2">
                Cargando
              </p>
            </div>
            <div v-else>
              <div class="encabezado-detalles">
                <header class="h4-responsive text-center pt-3">Detalles del cliente</header>
                <div class="row mx-0 mt-2">
                  <div :class="['col-12', !mostrarAsideActividad ? 'col-md-6 col-xl-5' : 'col-xxl-3']">
                    <span class="small-block font-titulo">Nombre o razón social</span>
                    <p class="text-capitalize">{{empresa && empresa.nom_emp}}</p>
                  </div>
                  <div class="col-12 col-sm-4 col-md-6 col-xl">
                    <span class="small-block font-titulo">RIF</span>
                    <p class="text-capitalize">{{empresa && empresa.rif_emp}}</p>
                  </div>
                  <div class="col-12 col-sm-4 col-md-6 col-xl">
                    <span class="small-block font-titulo">Relación</span>
                    <p class="text-capitalize">{{empresa && empresa.nom_rel}}</p>
                  </div>
                  <div class="col-12 col-sm-4 col-md-6 col-xl">
                    <span class="small-block font-titulo">Teléfono</span>
                    <p>{{empresa && empresa.tel_emp && mostrarNumeroEmpresa(empresa.tel_emp)}}</p>
                  </div>
                  <div
                    v-if="empresa && empresa.ema_emp && empresa && empresa.ema_emp.length"
                    :class="['col-12', !mostrarAsideActividad ? 'col-md-6 col-xl-5': 'col-xl-auto col-xxl-fixed']"
                  >
                    <span class="small-block font-titulo">Email</span>
                    <p>{{empresa && empresa.ema_emp}}</p>
                  </div>
                  <div :class="['col-12', !mostrarAsideActividad ? 'col-md-6 col-xl-7': 'col-xl-auto col-xxl-fixed']">
                    <span class="small-block font-titulo">Dirección</span>
                    <p>{{empresa && empresa.dir_emp}}</p>
                  </div>
                </div>
              </div>
              <h4 class="mt-2 d-inline">Sucursales</h4>
              <template v-if="Object.values(sucursales).length">
                <mdb-btn
                  v-for="suc in sucursales"
                  :key="'suc'+suc.id_suc"
                  flat
                  :class="['chip', {'primario activo': collapse === suc.id_suc}]"
                  @click.native.prevent="collapse === suc.id_suc ? collapse = 0 : [collapse = suc.id_suc, idSucursalSeleccionada = suc.id_suc, alertaActividadPendiente()];"
                >
                  {{suc.nom_suc}}
                  <font-awesome-icon class="ml-1" v-if="(collapse === suc.id_suc)" icon="check-circle"/>
                </mdb-btn>
              </template>
              <div
                v-else
                class="mensaje-no-items"
              >
                <font-awesome-icon
                  icon="map-signs"
                  size="5x"
                  class="icono"
                />
                <p class="texto text-center">
                  La empresa no tiene sucursales registradas
                  <span class="d-block">
                    Haz clic en el botón
                    <span class="text-uppercase font-weight-bold mx-2">
                      <font-awesome-icon icon="plus" size="1x" class="" />
                      Agregar sucursal
                    </span>
                    para crear una
                  </span>
                </p>
              </div>
              <transition name="tabActiva-transicion">
                <div
                  v-if="collapse"
                  class="row mx-0 mb-4"
                >
                  <div class="col-12 my-2 text-center">
                    <mdb-btn
                      flat
                      dark-waves
                      class="mx-0 mx-md-2 px-1 px-sm-3"
                      icon="trash"
                      :disabled="botonDeshabilitado"
                      @click="modalConfirmacionEliminar = !modalConfirmacionEliminar"
                    >
                      Eliminar
                    </mdb-btn>
                    <mdb-btn
                      flat
                      dark-waves
                      class="mx-0 mx-md-2 px-1 px-sm-3"
                      icon="pen"
                      @click="esEditar = true; esSucursal=true; sucursalSeleccionada =  sucursales[idSucursalSeleccionada];mostrarFormularioCliente = !mostrarFormularioCliente"
                    >
                      Editar sucursal
                    </mdb-btn>
                    <mdb-btn
                      color="secundario"
                      class="mx-0 mx-md-2 px-sm-3"
                      icon="plus"
                      @click="mostrarAsideActividad=true;"
                    >
                      <span>{{ sucursalesConActividadesPendientes.includes(idSucursalSeleccionada) ? 'Re-Tomar actividad' : 'Tomar actividad' }}</span>
                    </mdb-btn>
                  </div>
                  <div :class="['col-12', mostrarAsideActividad ? 'col-xl-auto col-xxl-fixed' : 'col-md']">
                    <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].tipo_suc && sucursales[idSucursalSeleccionada].tipo_suc.length">
                      <span class="small-block font-titulo">
                        Tipo de sucursal
                      </span>
                      <span class="text-capitalize">
                        {{sucursales[idSucursalSeleccionada].tipo_suc}}
                      </span>
                    </p>
                    <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].sada_suc && sucursales[idSucursalSeleccionada].sada_suc.length">
                      <span class="small-block font-titulo">
                        Sada
                      </span>
                      {{sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].sada_suc}}
                    </p>
                    <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].dir_suc && sucursales[idSucursalSeleccionada].dir_suc.length">
                      <span class="small-block font-titulo">
                        Dirección
                      </span>
                        {{sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].dir_suc}}
                      <a
                        v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].lon_suc && sucursales[idSucursalSeleccionada].lon_suc.length && sucursales[idSucursalSeleccionada].lat_suc && sucursales[idSucursalSeleccionada].lat_suc.length"
                        :href="`https://www.google.com/maps/search/?api=1&query=${sucursales[idSucursalSeleccionada].lat_suc},${sucursales[idSucursalSeleccionada].lon_suc}`"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="btn btn-flat ripple-parent my-0 py-1 px-3"
                      >
                        <font-awesome-icon icon="map-marked-alt" class="mr-1" />
                        Ver mapa
                      </a>
                    </p>
                    <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].est_zon && sucursales[idSucursalSeleccionada].ciu_zon && sucursales[idSucursalSeleccionada].sec_zon &&  sucursales[idSucursalSeleccionada].est_zon.length && sucursales[idSucursalSeleccionada].ciu_zon.length && sucursales[idSucursalSeleccionada].sec_zon.length">
                      <span class="small-block font-titulo">
                        Zona
                      </span>
                      {{sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].est_zon}}, {{sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].ciu_zon}}, {{sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].sec_zon}}
                    </p>
                    <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].nom_res &&  sucursales[idSucursalSeleccionada].nom_res.length">
                      <span class="small-block font-titulo">
                        Agente de retención
                      </span>
                      {{sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].nom_res}}
                    </p>
                    <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].sco_suc && sucursales[idSucursalSeleccionada].sco_suc.length">
                      <span class="small-block font-titulo">
                        Score
                      </span>
                      {{sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].sco_suc}}
                    </p>
                    <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].cod_suc && sucursales[idSucursalSeleccionada].cod_suc.length">
                      <span class="small-block font-titulo">
                        Código de la surcursal en sistema de facturación
                      </span>
                      {{sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].cod_suc}}
                    </p>
                    <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].ema_suc && sucursales[idSucursalSeleccionada].ema_suc.length">
                      <span class="small-block font-titulo">
                        Email sucursal
                      </span>
                      {{sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].ema_suc}}
                    </p>
                    <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].nom_per_ven && sucursales[idSucursalSeleccionada].nom_per_ven.length">
                      <span class="small-block font-titulo">
                        Vendedor
                      </span>
                      {{sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].nom_per_ven}}
                    </p>
                    <div
                      v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].nom_per_enc && sucursales[idSucursalSeleccionada].nom_per_enc.length"
                      class="mb-3"
                    >
                      <p>
                        <span class="small-block font-titulo">
                          Encargado
                        </span>
                        {{sucursales[idSucursalSeleccionada] && capitalizar(sucursales[idSucursalSeleccionada].nom_per_enc)}}
                        {{sucursales[idSucursalSeleccionada] && capitalizar(sucursales[idSucursalSeleccionada].ape_per_enc)}}
                      </p>
                      <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].ced_per_enc && sucursales[idSucursalSeleccionada].ced_per_enc.length">
                        <span class="small-block font-titulo">
                          Cédula del encargado
                        </span>
                        V- {{sucursales[idSucursalSeleccionada].ced_per_enc}}.
                      </p>
                      <p v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].ema_per_enc && sucursales[idSucursalSeleccionada].ema_per_enc.length">
                        <span class="small-block font-titulo">
                          Email del encargado
                        </span>
                        {{sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].ema_per_enc}}
                      </p>
                      <div
                        class="d-inline-block"
                        v-if="sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].tel_per_enc && sucursales[idSucursalSeleccionada].tel_per_enc.length"
                      >
                        <span class="small-block font-titulo">
                          Teléfono del encargado
                        </span>
                        {{ mostrarNumeroEmpresa(sucursales[idSucursalSeleccionada].tel_per_enc) }}
                        <mdb-btn
                          flat
                          dark-waves
                          icon="phone"
                          class="m-0 py-1 px-3"
                          tag="a"
                          :href="`tel:${mostrarNumeroEmpresa(sucursales[idSucursalSeleccionada].tel_per_enc)}`"
                        />
                        <mdb-btn
                          flat
                          dark-waves
                          icon="envelope"
                          class="m-0 py-1 px-3"
                          tag="a"
                          :href="`sms:${mostrarNumeroEmpresa(sucursales[idSucursalSeleccionada].tel_per_enc)}`"
                        />
                        <mdb-btn
                          flat
                          dark-waves
                          fab icon="whatsapp"
                          class="m-0 py-1 px-3"
                          tag="a"
                          target="_blank"
                          :href="`https://wa.me/${sucursales[idSucursalSeleccionada].tel_per_enc}`"
                        />
                      </div>
                    </div>
                    <div
                      v-if="sucursales[idSucursalSeleccionada]
                        && sucursales[idSucursalSeleccionada].tel_suc
                        && sucursales[idSucursalSeleccionada].tel_suc.length"
                      class="mb-3"
                    >
                      <span class="small-block font-titulo">
                        Teléfono sucursal
                      </span>
                        {{ mostrarNumeroEmpresa(sucursales[idSucursalSeleccionada].tel_suc) }}
                      <div class="d-inline-block">
                        <mdb-btn
                          flat
                          dark-waves
                          icon="phone"
                          class="m-0 py-1 px-3"
                          tag="a"
                          :href="`tel:${mostrarNumeroEmpresa(sucursales[idSucursalSeleccionada].tel_suc)}`"
                        />
                        <mdb-btn
                          flat
                          dark-waves
                          icon="envelope"
                          class="m-0 py-1 px-3"
                          tag="a"
                          :href="`sms:${mostrarNumeroEmpresa(sucursales[idSucursalSeleccionada].tel_suc)}`"
                        />
                        <mdb-btn
                          flat
                          dark-waves
                          fab icon="whatsapp"
                          class="m-0 py-1 px-3"
                          tag="a"
                          target="_blank"
                          :href="`https://wa.me/${sucursales[idSucursalSeleccionada].tel_suc}`"
                        />
                      </div>
                    </div>
                    <div v-if="contactos[idSucursalSeleccionada] !== 'undefined'">
                      <div
                        v-for="con in contactos[idSucursalSeleccionada]"
                        :key="'adi'+con.id_dat"
                        class="mb-2"
                      >
                        <span class="small-block font-titulo">
                          {{con.obs_dat}}
                          <mdb-badge
                            v-if="con.tel_cat || false"
                            pill
                            color="unique-color-dark"
                            class="z-depth-0"
                          >
                            {{ con.tel_cat }}
                          </mdb-badge>
                        </span>
                        {{mostrarNumeroEmpresa(con.cam_dat)}}
                        <mdb-btn
                          flat
                          dark-waves
                          icon="phone"
                          class="m-0 py-1 px-3"
                          tag="a"
                          :href="`tel:${mostrarNumeroEmpresa(con.cam_dat)}`"
                        />
                        <mdb-btn
                          flat
                          dark-waves
                          icon="envelope"
                          class="m-0 py-1 px-3"
                          tag="a"
                          :href="`sms:${mostrarNumeroEmpresa(con.cam_dat)}`"
                        />
                        <mdb-btn
                          flat
                          dark-waves
                          fab icon="whatsapp"
                          class="m-0 py-1 px-3"
                          tag="a"
                          target="_blank"
                          :href="`https://wa.me/${con.cam_dat}`"
                        />
                        <div class="row">
                          <mdb-btn
                            flat
                            dark-waves
                            title="Editar contacto"
                            class="py-2 m-0 "
                            icon="pen"
                            type="submit"
                            @click.native.prevent="añadir.estado = !añadir.estado; selecTel(con.cam_dat, con.obs_dat, con.id_dat, con.id_catcon)"
                          >
                            Editar
                          </mdb-btn>
                          <mdb-btn
                            flat
                            dark-waves
                            title="Eliminar contacto"
                            class="py-2 m-0"
                            icon="trash"
                            type="submit"
                            @click.native.prevent="modalConfirmacionEliminar = true; idCon = con.id_dat; conParaEliminar = `${con.obs_dat}`"
                          >
                            Eliminar
                          </mdb-btn>
                        </div>
                      </div>
                    </div>
                    <mdb-btn
                      flat
                      dark-waves
                      :class="['m-0 py-2 px-1 px-sm-3', {'mb-5' : !añadir.estado }]"
                      icon="plus"
                      @click.native.prevent="añadir.estado = !añadir.estado; selecTel()"
                    >
                      Agregar teléfonos adicionales
                    </mdb-btn>
                    <div class="ocultar-overflow">
                      <transition
                        name="custom-classes-transition"
                        enter-active-class="animated flipInX"
                        leave-active-class="animated flipOutY"
                        :duration="500"
                        @after-enter="$refs.inputNuevoContacto.focus()"
                      >
                        <form
                          v-show="añadir.estado"
                          class="col-12 col-sm-6 col-md col-xl-9 my-4 p-0"
                          @submit.prevent="añadir.accion === 'agg' ? aggTelAdicionales(): editarTelAdicionales()"
                        >
                          <div class="row justify-content-center">
                            <div class="col-12">
                              <mdb-input
                                v-model.number="telefonoAgg"
                                ref="inputNuevoContacto"
                                label="Teléfono"
                                placeholder="Ej: 4127778888"
                                minlength="9"
                                maxlength="10"
                                type="tel"
                                invalidFeedback="Ej: 4142702277"
                                outline
                                :class="['my-2 text-left', validarValores.numero]"
                              />
                            </div>
                            <div class="col-12">
                              <div :class="['my-2 p-0 md-form md-outline outline-select',  validarValores.categoria]">
                                <select
                                  id="telCat"
                                  v-model="telCat"
                                  class="custom-select"
                                >
                                  <option class="d-none" disabled :value="{}">Seleccione</option>
                                  <option
                                    v-for="cat in catcon"
                                    :key="cat.id_catcon"
                                    :value="cat.id_catcon"
                                  >
                                    {{cat.nombre_catcon}}
                                  </option>
                                </select>
                                <label
                                  for="telCat"
                                  :class="Object.values(telCat).length
                                    ? 'label-active'
                                    : 'label-inactive'"
                                >
                                  Categoría
                                </label>
                              </div>
                            </div>
                            <div class="col-12">
                              <mdb-input
                                v-model.trim="personaConAgg"
                                label="Persona de contacto"
                                invalidFeedback="Ej: Espinoza"
                                :class="['my-2', validarValores.persona]"
                                outline
                                @keyup.enter="submit"
                              />
                            </div>
                            <div class="col-auto">
                              <mdb-btn
                                :title="añadir.accion === 'agg' ? 'Añadir nuevo contacto' : 'Actualizar contacto'"
                                color="secundario"
                                :icon="añadir.accion === 'agg' ? 'check' : 'sync'"
                                type="submit"
                              >
                                {{ añadir.accion === 'agg' ? 'Guardar' : 'Actualizar' }}
                              </mdb-btn>
                            </div>
                          </div>
                        </form>
                      </transition>
                    </div>
                  </div>
                  <div :class="['col-12 mt-4 mt-xl-0', mostrarAsideActividad ? 'px-md-0 col-xl-auto col-xxl-fixed' : 'col-md-7 mt-md-0']">
                    <div v-if="pedidos[idSucursalSeleccionada]">
                      <ul class="table p-sucursal">
                        <li class="encabezado sticky">
                          <h4 class="text-center m-2">Últimos pedidos</h4>
                          <div class="thead claro">
                            <div class="col-sm-3 col-md-3">
                              Fecha
                              <span class="small-block">
                                del pedido
                              </span>
                            </div>
                            <div class="col-1 col-xl-2">ID</div>
                            <div class="col-12 col-sm text-right">
                              Estado
                              <span class="small-block">
                                Monto
                              </span>
                            </div>
                            <div class="col">Observaciones</div>
                          </div>
                        </li>
                        <li
                          v-for="(pedido, p) in pedidos[idSucursalSeleccionada]"
                          :key="'ped'+pedido.id_ped+p"
                          class="contenido cursor-pointer"
                          @click="$router.push(`/pedido/${pedido.id_ped}`)"
                        >
                          <div
                            class="col-sm-3 col-md-3"
                            data-columna="Fecha del pedido"
                          >
                            {{pedido.fec_ped}}
                          </div>
                          <div
                            class="col-1 col-xl-2"
                            data-columna="ID"
                          >
                            {{pedido.id_ped}}
                          </div>
                          <div
                            class="col-12 col-sm text-sm-right"
                            data-columna="Estado / Monto"
                          >
                            <p>
                              <span :class="[asignarColorEstado(pedido.est_ped), 'font-weight-bold']">
                                {{ pedido.est_ped.slice(0,1).toUpperCase() + pedido.est_ped.slice(1) }}
                              </span>
                              <span class="small-block">
                                {{pedido.mon_ped}}
                              </span>
                            </p>
                          </div>
                          <div
                            class="col text-break"
                            data-columna="Observaciones"
                          >
                            <span :class="{'text-muted font-italic user-select-none': !pedido.obs_ped }">
                              {{pedido.obs_ped ? pedido.obs_ped  : 'Sin especificar'}}
                            </span>
                          </div>
                        </li>
                        <router-link
                          :to="{name: 'Pedidos', params: {sucursal: idSucursalSeleccionada}}"
                          v-slot="{navigate}"
                          custom
                        >
                          <li class="contenido justify-content-center">
                            <mdb-btn
                              flat
                              dark-waves
                              icon="eye"
                              @click="navigate"
                            >
                              Ver más pedidos
                            </mdb-btn>
                          </li>
                        </router-link>
                      </ul>
                    </div>
                    <div
                      v-else
                      class="mensaje-no-items pb-3"
                    >
                      <h4 class="text-center m-2">Últimos pedidos</h4>
                      <font-awesome-icon
                        icon="clipboard"
                        size="5x"
                        class="icono"
                      />
                      <p class="texto">No hay pedidos</p>
                    </div>
                    <div v-if="notasInventario[idSucursalSeleccionada]">
                      <ul class="table p-sucursal">
                        <li class="encabezado sticky">
                          <h4 class="text-center m-2">Últimas tomas de inventario</h4>
                          <div class="thead claro">
                            <div class="col-sm-5">
                              Fecha
                              <span class="small-block">
                                del inventario
                              </span>
                            </div>
                            <div class="col-1 col-sm">ID</div>
                            <div class="col-1 col-sm">
                              ID Actividad
                            </div>
                          </div>
                        </li>
                        <li
                          v-for="(inventario, iI) in notasInventario[idSucursalSeleccionada]"
                          :key="'inv'+inventario.id_isu+iI"
                          class="contenido cursor-pointer"
                          @click="fechaDeInventarioSeleccionado = inventario.fec_isu;
                            inventario.id_int_isu ? $router.push({ name: 'ClienteActividad', params: { idActividad: inventario.id_int_isu } }) : ''"
                        >
                          <div
                            class="col-sm-5"
                            data-columna="Fecha del inventario"
                          >
                            {{formatearFecha(inventario.fec_isu).yyyymmdd}}
                          </div>
                          <div
                            class="col-1 col-sm"
                            data-columna="ID"
                          >
                            {{inventario.id_isu}}
                          </div>
                          <div
                            class="col-12 col-sm"
                            data-columna="ID Actividad"
                          >
                            <p>
                              <span :class="{'text-muted font-italic user-select-none' : !inventario.id_int_isu}">
                                {{inventario.id_int_isu ? inventario.id_int_isu : 'Sin especificar'}}
                              </span>
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div v-if="facturas[idSucursalSeleccionada]">
                      <ul class="table p-sucursal">
                        <li class="encabezado sticky">
                          <h4 class="text-center m-2">Últimas facturas</h4>
                          <div class="thead claro">
                            <div class="col-sm col-md-3">
                              Fecha del pago
                              <span class="small-block">
                                Fecha de vencimiento
                              </span>
                            </div>
                            <div class="col-12 col-sm-3">
                              Monto
                              <span class="small-block">
                                N° de factura
                              </span>
                            </div>
                            <div class="col-12 col-sm-2">
                              Estatus
                              <span class="small-block">
                                Pagos
                              </span>
                            </div>
                            <div class="col">Observaciones</div>
                          </div>
                        </li>
                        <li
                          v-for="(factura, p) in facturas[idSucursalSeleccionada]"
                          :key="'ped'+factura.id_fac+p"
                          class="contenido"
                        >
                          <div
                            class="col-sm col-md-3"
                            data-columna="Fecha del pago / Fecha de vencimiento"
                          >
                            <p>
                              {{factura.fec_pag_fac}}
                              <span class="small-block">
                                {{factura.fec_ven_fac}}
                              </span>
                            </p>
                          </div>
                          <div
                            class="col-12 col-sm-3"
                            data-columna="Monto / N° de factura"
                          >
                            <p>
                              {{factura.mon_fac}}
                              <span class="small-block">
                                {{factura.num_fac}}
                              </span>
                            </p>
                          </div>
                          <div
                            class="col-12 col-sm-2"
                            data-columna="Estatus / Pagos"
                          >
                            <p>
                              <span :class="[asignarColorEstado(factura.est_fac), 'font-weight-bold']">
                                {{factura.est_fac}}
                              </span>
                              <span class="small-block">
                                {{factura.pagos}}
                              </span>
                            </p>
                          </div>
                          <div
                            class="col text-break"
                            data-columna="Observaciones"
                          >
                            <span :class="{'text-muted font-italic user-select-none': !factura.obs_fac }">
                              {{factura.obs_fac ? factura.obs_fac  : 'Sin especificar'}}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div v-if="actividades[idSucursalSeleccionada]">
                      <ul class="table p-sucursal">
                        <li class="encabezado sticky">
                          <h4 class="text-center m-2">Últimas actividades</h4>
                          <div class="thead claro">
                            <div class="col-sm col-md-3">
                              Fecha de la actividad
                            </div>
                            <div class="col">
                              Acciones
                            </div>
                            <div class="col">Observaciones</div>
                          </div>
                        </li>
                        <li
                          v-for="(act, p) in actividades[idSucursalSeleccionada]"
                          :key="'act'+act.id_int+p"
                          class="contenido"
                        >
                          <div
                            class="col-sm col-md-3"
                            data-columna="Fecha de la actividad"
                          >
                            <p>
                              {{ act.fecha_int }}
                            </p>
                          </div>
                          <div
                            class="col text-break"
                            data-columna="Acciones"
                          >
                            <p>
                              {{ act.acciones }}
                            </p>
                          </div>
                          <div
                            class="col text-break"
                            data-columna="Observaciones"
                          >
                            <span :class="{'text-muted font-italic user-select-none': !act.obs_int }">
                              {{act.obs_int ? act.obs_int  : 'Sin especificar'}}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="!collapse && Object.values(sucursales).length"
                  class="mensaje-no-items pt-4 pb-5"
                >
                  <font-awesome-icon
                    icon="store"
                    size="5x"
                    class="icono"
                  />
                  <p class="texto text-center">
                    Seleccione una sucursal para
                    <span class="d-block">
                      ver sus detalles
                    </span>
                  </p>
                </div>
              </transition>
            </div>
          </section>
          <transition
            name="custom-classes-transition"
            enter-active-class="animated fadeInRight"
            leave-active-class="animated fadeOutRight"
          >
            <router-view :key="$route.fullPath" :fecha="fechaDeInventarioSeleccionado"></router-view>
          </transition>
        </div>
      </div>
    </article>
    <NuevaActividad
      :mostrarAsideActividad="mostrarAsideActividad"
      :idSucursal="idSucursalSeleccionada ? idSucursalSeleccionada : ''"
      :empresa="empresa ||{} "
      :adicionalesSucursal="{
        nom_suc: sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].nom_suc,
        sec_zon: sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].sec_zon,
        est_zon: sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].est_zon,
        ciu_zon: sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].ciu_zon,
      }"
      :adicionalesDatosPago="{
        // Datos requeridos de la empresa
        id_emp: empresa && empresa.id_emp,
        nom_emp: empresa && empresa.nom_emp,
        rif_emp: empresa && empresa.rif_emp,
        // Datos requeridos de la sucursal
        id_emp_suc: empresa && empresa.id_emp,
        id_suc: sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].id_suc,
        id_tcl_suc: sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].id_tcl_suc,
        id_zon_suc: sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].id_zon_suc,
        nom_suc: sucursales[idSucursalSeleccionada] && sucursales[idSucursalSeleccionada].nom_suc,
      }"
      @cerrar="mostrarAsideActividad=false"
      @actualizar="cargaInicial"
      />
    <FormularioCliente
      :zonas="zonasFiltro"
      :tipos="tiposFiltro"
      :retenciones="retFiltro"
      :empresaId="empresa ? empresa.id_emp : ''"
      :empresa="empresa ? empresa : {}"
      :sucursal="sucursalSeleccionada ? sucursalSeleccionada : {}"
      :esSucursal="esSucursal"
      :esCliente="esCliente"
      :esEditar="esEditar"
      :mostrarModal="mostrarFormularioCliente"
      @alertaMensaje="alertaMensaje = $event"
      @cerrar="mostrarFormularioCliente=false; esSucursal = false; esCliente = false; esEditar = false;"
      @clienteAgregado="cargaInicial()"
    />
    <ConfirmacionEliminar
      :boton-deshabilitado="botonDeshabilitado"
      :mostrar-modal="modalConfirmacionEliminar"
      titulo="¡Esta acción es irreversible!"
      :mensaje="!conParaEliminar ? `Se eliminará en su totalidad la sucursal.`: `Esta acción eliminará el contacto de: ${conParaEliminar}`"
      @cerrar="modalConfirmacionEliminar = false; conParaEliminar = ''"
      @confirmar="!conParaEliminar ? eliminarSucursal() : EliminarTelAdicionales()"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>

<script>
import {
  apiPost,
} from '@/funciones/api.js';
import ConfirmacionEliminar from '@/components/ConfirmacionEliminar.vue';
import NuevaActividad from '@/components/NuevaActividad.vue';
import {mdbBtn, mdbInput, mdbBadge} from 'mdbvue';
import {validarTexto, capitalizarPalabras, asignarColorEstado, capitalizar, formatearFecha, validarTel} from '@/funciones/funciones.js';
import AlertaMensaje from '@/components/AlertaMensaje.vue';
import FormularioCliente from '@/components/modals/FormularioCliente.vue';
import LoaderKel from '@/components/LoaderKel.vue';

export default {
  name: 'Cliente',
  components: {
    NuevaActividad,
    mdbBtn,
    mdbInput,
    mdbBadge,
    FormularioCliente,
    AlertaMensaje,
    ConfirmacionEliminar,
    LoaderKel,
  },
  data() {
    return {
      telCat: '',
      catcon: [],
      fechaDeInventarioSeleccionado: '',
      capitalizar,
      asignarColorEstado,
      formatearFecha,
      alertaMensaje: {contenido: ''},
      idSucursalSeleccionada: 0,
      sucursalSeleccionada: {},
      sucursales: {},
      contactos: {},
      facturas: {},
      empresa: {},
      actividades: {},
      pedidos: {},
      tomasInventario: {},
      mostrarAsideActividad: false,
      collapse: 0,
      añadir: {
        estado: false,
        accion: '',
      },
      telefonoAgg: '',
      personaConAgg: '',
      idEdiTel: '',
      esCliente: false,
      esSucursal: false,
      esEditar: false,
      mostrarFormularioCliente: false,
      zonasFiltro: [],
      tiposFiltro: [],
      retFiltro: [],
      validarValores: {},
      sucursalesConActividadesPendientes: [],
      // Eliminar sucursal
      botonDeshabilitado: false,
      conParaEliminar: '',
      modalConfirmacionEliminar: false,
      obteniendoDatosCache: false,
      cargando: false,
      notasInventario: {},
    };
  },
  watch: {
    idSucursalSeleccionada() {
      this.obtenerActividadesAunSinEntregar();
    },
    mostrarAsideActividad() {
      if (!this.mostrarAsideActividad) {
        this.obtenerActividadesAunSinEntregar();
      }
    },
    sucursales(valor) {
      const listaSucursales = Object.values(valor);
      if (listaSucursales.length === 1) {
        this.collapse = listaSucursales[0].id_suc;
        this.idSucursalSeleccionada = listaSucursales[0].id_suc;
        this.alertaActividadPendiente();
      }
    },
  },
  computed: {
    mostrandoComponenteHijo() {
      return this.$route.params.idActividad ? true : false;
    },
  },
  methods: {
    eliminarSucursal() {
      this.botonDeshabilitado = true;
      apiPost({s: 'sucursalEliminarMv'}, {suc: this.idSucursalSeleccionada})
          .then((res) => {
            if (res.data.r) {
              this.alertaMensaje = {
                contenido: 'Operación exitosa',
                tipo: 'correcto',
              };
              this.modalConfirmacionEliminar = false;
              this.idSucursalSeleccionada = 0;
              this.collapse = 0;
              this.botonDeshabilitado = false;
              this.cargaInicial();
            } else {
              this.botonDeshabilitado = false;
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un problema',
                tipo: 'error',
              };
            }
          });
    },
    obtenerActividadesAunSinEntregar() {
      if (this.obteniendoDatosCache) return;
      this.obteniendoDatosCache = true;
      const sucursal = window.localStorage.getItem(`datos-actividad${this.$route.params.id}-${this.idSucursalSeleccionada}`);
      if (sucursal) {
        // Si la actividad no está ya agregada se agrega
        if (!this.sucursalesConActividadesPendientes.includes(this.idSucursalSeleccionada)) {
          this.sucursalesConActividadesPendientes.push(this.idSucursalSeleccionada);
        }
        // Si la actividad ya se encuentra agregada entonces no se modifica
      } else {
        this.sucursalesConActividadesPendientes = this.sucursalesConActividadesPendientes.filter((s) => s != this.idSucursalSeleccionada);
      }
      this.obteniendoDatosCache = false;
    },
    alertaActividadPendiente() {
      this.obtenerActividadesAunSinEntregar();
      if (this.sucursalesConActividadesPendientes.includes(this.idSucursalSeleccionada)) {
        this.alertaMensaje = {
          contenido: 'Esta sucursal tiene una actividad pendiente por enviar',
          tipo: 'alerta',
        };
      }
    },
    cargaInicial() {
      this.cargando = true;
      this.resultados = {};
      apiPost({s: 'empresa'}, 'cliente_id=' + this.$route.params.id)
          .then((res) => {
            this.enlistarSucursales(res.data.suc);
            this.enlistarPedidos(res.data.ped);
            this.enlistarInventario(res.data.inv);
            this.enlistarFacturas(res.data.facturas);
            this.enlistarActividades(res.data.act);
            this.enlistarContactos(res.data.con);
            this.catcon = res.data.catcon;
            this.empresa = res.data.emp;
          });
      // Obtener zonas y tipos de sucursales / empresa
      apiPost({s: 'zonasMv'}, '').then((res) => {
        this.zonasFiltro = res.data.zon;
      });
      apiPost({s: 'tCliMv'}, '').then((res) => {
        this.tiposFiltro = res.data.tcli;
      });
      // Obtener retenciones
      apiPost({s: 'retencionesMv'}, '').then((res) => {
        // Datos de selección de empresas
        this.retFiltro = res.data.rets;
      });
    },
    enlistarFacturas(data) {
      const facturas = {};
      data.forEach((p) => {
        if (!facturas[p.id_suc_fac]) {
          facturas[p.id_suc_fac] = [];
        }
        facturas[p.id_suc_fac].push({
          est_fac: p.est_fac,
          fec_ven_fac: p.fec_ven_fac,
          fec_pag_fac: p.fec_pag_fac,
          id_fac: p.id_fac,
          num_fac: p.num_fac,
          mon_fac: p.mon_fac,
          obs_fac: p.obs_fac,
          pagos: p.pagos,
          edit_fac: false,
        });
      });
      this.facturas = facturas;
    },
    enlistarActividades(data) {
      const actividades = {};
      data.forEach((p) => {
        if (!actividades[p.id_suc]) {
          actividades[p.id_suc] = [];
        }
        actividades[p.id_suc].push({
          fecha_int: p.fecha_int,
          acciones: p.acciones,
          obs_int: p.obs_int,
          id_int: p.id_int,
        });
      });
      this.actividades = actividades;
    },
    enlistarSucursales(data) {
      const sucursal = {};
      data.forEach((s) => {
        if (!sucursal[s.id_suc]) {
          sucursal[s.id_suc] = {
            id_suc: s.id_suc,
            nom_suc: s.nom_suc,
            sada_suc: s.sada_suc,
            nom_res: s.nom_res,
            cod_suc: s.cod_suc,
            tel_suc: s.tel_suc,
            ema_suc: s.ema_suc,
            dir_suc: s.dir_suc,
            est_zon: s.est_zon,
            ciu_zon: s.ciu_zon,
            id_tcl_suc: s.id_tcl_suc,
            id_res_suc: s.id_res_suc,
            id_zon_suc: s.id_zon_suc,
            sec_zon: s.sec_zon,
            nom_per_ven: s.nom_per_ven,
            sco_suc: s.sco_suc,
            est_fac: s.est_fac,
            id_enc: s.id_enc,
            nom_per_enc: s.nom_per_enc,
            ape_per_enc: s.ape_per_enc,
            ema_per_enc: s.ema_per_enc,
            ced_per_enc: s.ced_per_enc,
            tel_per_enc: s.tel_per_enc,
            dir_enc: s.dir_enc,
            lat_suc: s.lat_suc,
            lon_suc: s.lon_suc,
            email_dat: {},
            tipo_suc: s.nom_tcl,
          };
        }
      });
      this.sucursales = sucursal;
      this.cargando = false;
    },
    formatearTelefono(telefono) {
      return telefono.slice(2);
    },
    enlistarPedidos(data) {
      const pedidos = {};
      data.forEach((p) => {
        if (!pedidos[p.id_suc_ped]) {
          pedidos[p.id_suc_ped] = [];
        }
        pedidos[p.id_suc_ped].push({
          est_ped: p.est_ped,
          fec_ped: p.fec_ped,
          id_ped: p.id_ped,
          mon_ped: p.mon_ped,
          obs_ped: p.obs_ped,
        });
      });
      this.pedidos = pedidos;
    },
    enlistarInventario(data) {
      const notasInventario = {};
      if (data) {
        data.forEach((p) => {
          if (!notasInventario[p.id_suc_isu]) {
            notasInventario[p.id_suc_isu] = [];
          }
          notasInventario[p.id_suc_isu].push({
            fec_isu: p.fec_isu,
            id_int_isu: p.id_int_isu,
            id_isu: p.id_isu,
          });
        });
        this.notasInventario = notasInventario;
      }
    },
    enlistarContactos(data) {
      const contacto = {};
      if (data) {
        data.forEach((c) => {
          if (!contacto[c.id_suc]) {
            contacto[c.id_suc] = [];
          }
          contacto[c.id_suc].push({
            id_dat: c.id_dat,
            cam_dat: c.cam_dat,
            obs_dat: c.obs_dat,
            tel_cat: c.nombre_catcon,
            id_catcon: c.id_catcon,
          });
        });
        this.contactos = contacto;
      }
    },
    EliminarTelAdicionales() {
      this.añadir.estado = false;
      apiPost({s: 'eliAdsM'}, 'dat=' + this.idCon)
          .then((res) => {
            if (res.data.r) {
              this.alertaMensaje = {
                contenido: 'Operación exitosa',
                tipo: 'correcto',
              };
              this.cargaInicial();
              this.idCon = '';
              this.conParaEliminar = '';
              this.modalConfirmacionEliminar = false;
            }
          }).catch(() => {
            this.alertaMensaje = {
              contenido: 'Ha ocurrido un problema eliminando el contacto',
              tipo: 'error',
            };
          });
    },
    validarTelefono(telefono) {
      if (!telefono || !validarTel(telefono, '58')) {
        this.alertaMensaje = {
          contenido: 'Teléfono no válido',
          tipo: 'advertencia',
        };
        return false;
      }
      return true;
    },
    validarTelefonosAdicionales() {
      const existeTelefonoDuplicado = this.validarTelefonoDuplicado();
      this.validarValores = {
        persona: !this.personaConAgg || this.personaConAgg === '' || !validarTexto(this.personaConAgg) ? 'invalido' : 'valido',
        numero: this.telefonoAgg && this.validarTelefono(this.telefonoAgg) || existeTelefonoDuplicado ? 'invalido' : 'valido',
        categoria: this.telCat.length ? 'valido-select' : 'invalido-select',
      };
      if (existeTelefonoDuplicado) {
        this.alertaMensaje = {
          contenido: 'El teléfono que intenta agregar ya se encuentra registrado en la sucursal',
          tipo: 'advertencia',
        };
        return false;
      }
      if (this.telefonoAgg == '') {
        this.alertaMensaje = {
          contenido: 'El teléfono es requerido',
          tipo: 'advertencia',
        };
        return false;
      }
      if (this.telCat == '') {
        this.alertaMensaje = {
          contenido: 'La categoría es requerida',
          tipo: 'advertencia',
        };
        return false;
      }
      if (!this.personaConAgg || this.personaConAgg === '') {
        this.alertaMensaje = {
          contenido: 'La persona de contacto es requerida',
          tipo: 'error',
        };
        return false;
      }
      this.personaConAgg = capitalizarPalabras(this.personaConAgg);
      if (!validarTexto(this.personaConAgg)) {
        if (this.personaConAgg.length < 2) {
          this.alertaMensaje = {
            contenido: 'La persona de contacto debe contener un nombre más largo',
            tipo: 'error',
          };
        } else {
          this.alertaMensaje = {
            contenido: 'La persona de contacto debe contener solo texto',
            tipo: 'error',
          };
        }
        return false;
      }
      return true;
    },
    editarTelAdicionales() {
      if (!this.validarTelefonosAdicionales()) return;
      const telefonoFormateado = '58' + this.telefonoAgg;
      apiPost({s: 'modAdsM'}, 'telad=' + telefonoFormateado + '&nomad=' + this.personaConAgg + '&dat=' + this.idEdiTel + '&catcon=' + this.telCat)
          .then((res) => {
            if (res.data.r) {
              this.alertaMensaje = {
                contenido: 'Operación exitosa',
                tipo: 'correcto',
              };
              this.cargaInicial();
              this.telefonoAgg = '';
              this.personaConAgg = '';
              this.telCat = '';
              this.validarValores = {};
              this.añadir.estado = false;
            }
          }).catch(() => {
            this.alertaMensaje = {
              contenido: 'Ha ocurrido un problema actualizando el contacto',
              tipo: 'error',
            };
          });
    },
    aggTelAdicionales() {
      if (!this.validarTelefonosAdicionales()) return;
      const telefonoFormateado = '58' + this.telefonoAgg;
      apiPost({s: 'aggAdsM'}, 'telad=' + telefonoFormateado + '&nomad=' + this.personaConAgg + '&l=' + this.idSucursalSeleccionada + '&sq=' + 'suc' + '&catcon=' + this.telCat)
          .then((res) => {
            if (res.data.r) {
              this.alertaMensaje = {
                contenido: 'Operación exitosa',
                tipo: 'correcto',
              };
              this.cargaInicial();
              this.telefonoAgg = '';
              this.personaConAgg = '';
              this.telCat = '';
              this.validarValores = {};
              this.añadir.estado = false;
            }
          }).catch(() => {
            this.alertaMensaje = {
              contenido: 'Ha ocurrido un problema agregando el contacto',
              tipo: 'error',
            };
          });
    },
    validarTelefonoDuplicado() {
      if (Object.keys(this.contactos).includes(this.idSucursalSeleccionada) && this.contactos[this.idSucursalSeleccionada].length) {
        const contactos = this.contactos[this.idSucursalSeleccionada].filter(({cam_dat: telefonoRegistrado}) => telefonoRegistrado === '58' + this.telefonoAgg);
        return contactos.length > 0 && contactos[0].id_dat !== this.idEdiTel;
      }
    },
    selecTel(conTel, conNom, conId, conCat) {
      this.validarValores = {};
      if (conTel) {
        this.telefonoAgg = this.formatearTelefono(conTel);
        this.personaConAgg = conNom;
        this.telCat = conCat;
        this.añadir.accion = 'edi';
        this.idEdiTel = conId;
      } else {
        this.añadir.accion = 'agg';
        this.telefonoAgg = '';
        this.personaConAgg = '';
      }
    },
    mostrarNumeroEmpresa(numeroEmpresa) {
      return `+${numeroEmpresa}`;
    },
  },
  mounted() {
    this.cargaInicial();
  },
};
</script>

<style lang="scss" scoped>
.table.p-sucursal {
  // Define los breakpoints para el responsive de ul.table
  $breakpointToChange: "576px";
  @include ul-table-responsive-at ($breakpointToChange);
}
.ocultar-overflow {
  overflow: hidden;
}
.cliente section {
  max-width: 1140px;
  transition: margin-right 1s ease;

  &.col-md {
    margin-right: 386px;
  }
}

.col-xxl-fixed {
  @media screen and (min-width: 1200px) {
    width: 100%;
  }
  @media screen and (min-width: 1500px) {
    width: 50%;
  }
}

.col-xxl-3 {
  @media screen and (min-width: 1500px) {
    max-width: 25% !important;
  }
}
</style>
